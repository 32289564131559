import { Routes } from '@angular/router';
import { ErrorNavigationComponent } from '../atoms/components/error-navigation/error-navigation.component';
import { AuthRoutingPaths } from '../auth/auth-routing.module';

export enum AppRoutesPaths {
    SEARCH = 'search',
    TREK = 'trek',
    SITE = 'site',
    ITINERARY = 'itinerary',
    PROFILE = 'profile',
    WISH_LIST = 'wish-list',
    USER = 'user',
    ORGANIZATION = 'organization',
    LIVE = 'live',
    CAMPAIGN = 'campaign',
    CERTIFICATES = 'certificates',
    CONTACT_US = 'contact-us',
    ERROR = 'error',
    HOME = 'home',
    AUTH = 'auth'
}

export const appRoutes: Routes = [
    {
        path: AppRoutesPaths.SEARCH,
        loadChildren: () =>
            import('../search/search.module').then((m) => m.SearchModule)
    },
    {
        path: AppRoutesPaths.TREK,
        loadChildren: () =>
            import('../trek/trek.module').then((m) => m.TrekModule)
    },
    {
        path: `${AppRoutesPaths.SITE}/:siteId/${AppRoutesPaths.ITINERARY}`,
        loadChildren: () =>
            import('../itinerary/itinerary.module').then(
                (m) => m.ItineraryModule
            )
    },
    {
        path: AppRoutesPaths.SITE,
        loadChildren: () =>
            import('../site/site-routing').then((m) => m.siteRoutes)
    },
    {
        path: AppRoutesPaths.PROFILE,
        loadChildren: () =>
            import('../profile/profile.module').then((m) => m.ProfileModule)
    },
    {
        path: AppRoutesPaths.WISH_LIST,
        loadChildren: () =>
            import('../wish-list/wish-list.module').then(
                (m) => m.WishListModule
            )
    },
    {
        path: AppRoutesPaths.USER,
        loadChildren: () =>
            import('../user/user.module').then((m) => m.UserModule)
    },
    {
        path: AppRoutesPaths.ORGANIZATION,
        loadChildren: () =>
            import('../organization/organization.module').then(
                (m) => m.OrganizationModule
            )
    },
    {
        path: AppRoutesPaths.LIVE,
        loadChildren: () =>
            import('../live/live.module').then((m) => m.LiveModule)
    },
    {
        path: AppRoutesPaths.CAMPAIGN,
        loadChildren: () =>
            import('../campaign/campaign.module').then((m) => m.CampaignModule)
    },
    {
        path: `${AppRoutesPaths.CERTIFICATES}/:id`,
        redirectTo: `${AppRoutesPaths.CAMPAIGN}/${AppRoutesPaths.CERTIFICATES}/:id`
    },
    {
        path: AppRoutesPaths.CONTACT_US,
        loadChildren: () =>
            import('../contact-us/contact-us.module').then(
                (m) => m.ContactUsModule
            )
    },
    {
        path: AppRoutesPaths.AUTH,
        loadChildren: () =>
            import('../auth/auth.module').then((m) => m.AuthModule)
    },
    {
        path: '',
        loadChildren: () =>
            import('../home/home.module').then((m) => m.HomeModule)
    },
    {
        path: AppRoutesPaths.ERROR,
        component: ErrorNavigationComponent,
        data: {
            errorMessage: 'THIS_PAGE_DOES_NOT_EXIST'
        }
    },
    {
        path: AuthRoutingPaths.RESET_PASSWORD,
        redirectTo: `${AppRoutesPaths.AUTH}/${AuthRoutingPaths.RESET_PASSWORD}`
    },
    {
        path: AuthRoutingPaths.UNSUBSCRIBE,
        redirectTo: `${AppRoutesPaths.AUTH}/${AuthRoutingPaths.UNSUBSCRIBE}`
    },
    {
        path: '**',
        redirectTo: `/${AppRoutesPaths.ERROR}`
    }
];
