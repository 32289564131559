<div class="error-navigation">
    <div
        class="error-navigation__title"
        [translate]="'THIS_PAGE_DOES_NOT_EXIST'"
        appTypography="HEADLINE"></div>
    <img
        [src]="imgLogo"
        alt=""
        class="error-navigation__logo" />
    <app-custom-button
        class="retry-btn"
        [link]="prevUrl || homeUrl">
        <span [translate]="prevUrl ? 'RETRY' : 'HOME'"></span>
    </app-custom-button>
</div>
