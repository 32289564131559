import { MetaService } from '../../../app/services/meta.service';
import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutesPaths } from '../../../app/app-routes';
import { environment } from '@env';
import { CustomButtonComponent } from '../buttons/custom-button/custom-button-component.component';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-error-navigation',
    standalone: true,
    templateUrl: './error-navigation.component.html',
    imports: [CustomButtonComponent, TypographyDirective, TranslateModule],
    styleUrls: ['./error-navigation.component.scss']
})
export class ErrorNavigationComponent implements AfterViewInit {
    imgLogo = `${environment.ASSETS_BASE_URL}/assets/images/wishtrip-logo-square.svg`;
    prevUrl: string;
    homeUrl = `/${AppRoutesPaths.HOME}`;

    constructor(
        private metaService: MetaService,
        private router: Router
    ) {
        this.prevUrl =
            this.router.getCurrentNavigation()?.extras.state?.prevUrl;
    }

    ngAfterViewInit() {
        this.metaService.setRobotTag('noindex, follow');
        this.metaService.setTags('Error: This page does not exist');
    }
}
